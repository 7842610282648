<template>
  <div>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="true"
      :float-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-content-width="auto"
      :html-to-pdf-options="htmlToPdfOptions"
      :paginate-elements-by-height="1100"
    >
      <section slot="pdf-content">
        <v-container
          id="dashboard-view"
          ref="container"
          class="white-bg"
          fluid
          tag="section"
        >
          <section class="pdf-item">
            <v-card id="content">
              <v-row>
                <v-col>
                  <div class="headerTitle">
                    <h3>A株式会社 御中</h3>
                    <p>いつも大変お世話になっております。</p>
                    <p>業務に伺いますスタッフのメンバー表になります。</p>
                    <p>ご確認の程宜しくお願い申し上げます。</p>
                  </div>
                </v-col>
                <v-col>
                  <div class="headerTitle headerRight">
                    <h3>株式会社 御中</h3>
                    <p>福岡営業所</p>
                    <p>☎ 092-710-5070 FAX: 06-7635-8173</p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" />
                <v-col cols="5">
                  <table class="tableSign">
                    <tr>
                      <th><h3>役員</h3></th>
                      <th><h3>次長</h3></th>
                      <th><h3>課長</h3></th>
                      <th><h3>総務</h3></th>
                    </tr>
                    <tr>
                      <td><signature-pad /></td>
                      <td><signature-pad /></td>
                      <td><signature-pad /></td>
                      <td><signature-pad /></td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="headerTable">
                    <h2>就業日：Tuesday, April 13, 2021</h2>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-5">
                  <table class="tableData">
                    <tr>
                      <th><h3>No</h3></th>
                      <th><h3>社員 番号</h3></th>
                      <th><h3>氏名</h3></th>
                      <th><h3>ニクネム</h3></th>
                      <th><h3>国籍</h3></th>
                      <th><h3>経験</h3></th>
                      <th><h3>出勤予定</h3></th>
                      <th><h3>体温</h3></th>
                      <th><h3>出勤</h3></th>
                      <th><h3>退勤</h3></th>
                      <th><h3>休憩</h3></th>
                      <th><h3>備考</h3></th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td><b>42093</b></td>
                      <td>
                        <div>
                          <p>プンドゥピン</p>
                          <p>PUN DUPIN</p>
                        </div>
                      </td>
                      <td><b>ドゥピン</b></td>
                      <td><b>ネパール</b></td>
                      <td>あり</td>
                      <td>12：45～19:00</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>ソーター</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td><b>60106</b></td>
                      <td>
                        <div>
                          <p>サティスクマル</p>
                          <p>SATISH KUMAR</p>
                        </div>
                      </td>
                      <td><b>ドゥピン</b></td>
                      <td><b>ネパール</b></td>
                      <td>あり</td>
                      <td>12：45～19:00</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>ソーター</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td><b>60219</b></td>
                      <td>
                        <div>
                          <p>マハテミオ マルチネス ドミンゲス</p>
                          <p>MARTINEZ DOMINGUEZ ARTEMIO</p>
                        </div>
                      </td>
                      <td><b>ドゥピン</b></td>
                      <td><b>ネパール</b></td>
                      <td>あり</td>
                      <td>12：45～19:00</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>ソーター</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td><b>60219</b></td>
                      <td>
                        <div>
                          <p>マハテミオ マルチネス ドミンゲス</p>
                          <p>MARTINEZ DOMINGUEZ ARTEMIO</p>
                        </div>
                      </td>
                      <td><b>ドゥピン</b></td>
                      <td><b>ネパール</b></td>
                      <td>あり</td>
                      <td>12：45～19:00</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>ソーター</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td><b>60219</b></td>
                      <td>
                        <div>
                          <p>マハテミオ マルチネス ドミンゲス</p>
                          <p>MARTINEZ DOMINGUEZ ARTEMIO</p>
                        </div>
                      </td>
                      <td><b>ドゥピン</b></td>
                      <td><b>ネパール</b></td>
                      <td>あり</td>
                      <td>12：45～19:00</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>ソーター</td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-card>
          </section>
        </v-container>
      </section>
    </vue-html2pdf>
    <v-row class="d-flex justify-end mt-3">
      <v-col cols="2">
        <v-btn
          large
          block
          color="info"
          @click="downloadWithCSS"
        >
          <span class="font-weight-bold text-h5">{{
            $t('messages.export')
          }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { sync } from 'vuex-pathify'
  import VueHtml2pdf from 'vue-html2pdf'
  import SignaturePad from '../../components/SignaturePad'
  export default {
    name: 'Sign',
    components: {
      VueHtml2pdf,
      SignaturePad,
    },
    data () {
      return {
        width: 1250,
        height: 800,
        htmlToPdfOptions: {
          margin: 0,
          filename: 'hehehe.pdf',
          image: {
            type: 'png',
            quality: 1,
          },

          enableLinks: false,

          jsPDF: {
            filename: 'download',
            unit: 'cm',
            format: 'a4',
            orientation: 'l',
          },
        },
      }
    },
    computed: {
      ...sync('app', ['drawer', 'mini']),
    },
    created () {
      window.addEventListener('resize', this.myEventHandler)
    },
    destroyed () {
      window.removeEventListener('resize', this.myEventHandler)
    },
    mounted () {
      this.htmlToPdfOptions.html2canvas = {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        allowTaint: false,
        scale: 2,
        useCORS: true,
        x: 0,
        y: 0,
        width: this.width,
        windowWidth: this.width,
      }
    },
    methods: {
      myEventHandler (e) {
        this.htmlToPdfOptions.html2canvas = {
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          allowTaint: false,
          scale: 2,
          useCORS: true,
          x: 0,
          y: 0,
          width: this.width,
          windowWidth: this.width,
        }
      },
      downloadWithCSS () {
        this.mini = true
        window.scrollTo(0, 0)
        this.$refs.html2Pdf.generatePdf()
      },
    },
  }
</script>

<style scoped lang="scss">
.headerTitle {
  width: 50%;
  text-align: center;
}
.white-bg {
  background: #ffffff;
}
.headerRight {
  float: right;
}

.tableSign {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: 10px;

  td,
  th {
    border: 1px solid #2d2d2d;
    padding: 8px;
    text-align: center;
    width: 100px;
  }
  tr {
    height: 100px;
  }
}
.headerTable {
  text-align: center;
}
.tableData {
  margin: 0 auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: 10px;

  td,
  th {
    padding: 8px;
    text-align: center;
  }

  table,
  th,
  td {
    border: 1px solid #2d2d2d;
  }
}
</style>
